import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';

import { FaPlusCircle } from 'react-icons/fa'
import SearchBackground from './SearchBackground'
import { MdOutlinePause, MdOutlineQueueMusic, MdPlayArrow, MdReplay, MdSkipNext, MdSkipPrevious } from 'react-icons/md';
// import ToolBox from './ToolBox'
import Canvas from './Canvas'
import '../css/VideoBackgrounds.css'
import AudioPlayer from 'react-h5-audio-player';
import Button from 'react-bootstrap/Button';
import ImageScroller from '../../SharedComponents/js/ImageScroller'
import SearchMusicTracks from './SearchMusicTracks';
import { useErrorBoundary } from 'react-error-boundary';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FiChevronsLeft, FiChevronsRight, FiPauseCircle, FiPlay } from 'react-icons/fi';
import Transitions from './Transitions';

const env = require('../../config.env')

const VideoBackgrounds = (props) => {
    const { showBoundary } = useErrorBoundary()
    const [text, setText] = useState();
    const [textColor, setTextColor] = useState("#ffffff");
    const [textFont, setTextFont] = useState("Poppings");
    const [fontSize, setFontSize] = useState(0.05);
    const [show_index, set_show_index] = useState(0)
    const [videoOpacity, setvideoOpacity] = useState(1);
    const [backgroundOpacity, setBackgroundOpacity] = useState(1);
    const [textBackground, setTextBackground] = useState("#050505");
    const [textBackground2, setTextBackground2] = useState("#0000");
    const [textBorderColor, setTextBorderColor] = useState("#050505");
    const [textBorderWidth, setTextBorderWidth] = useState(0);
    const [borderRadius, setBorderRadius] = useState(1);
    const [isRegenerate, setIsRegenerate] = useState(0)
    const scroll_index = useRef(0)
    const [selectedAudioName, set_selectedAudioName] = useState("Add music")
    const [selectedTrack, set_selectedTrack] = useState()
    const [selectedTrans, set_selectedTrans] = useState()
    const is_play = useRef([])
    const [track_is_play, set_track_is_play] = useState(false)
    const [musicpopup_show, set_musicpopup_show] = useState(false)
    const [transpopup_show, set_transpopup_show] = useState(false)
    const [summarized_sections, setSummarized_sections] = useState(props.sections)
    const [server_tracks, set_server_tracks] = useState([])
    const [server_transitions, set_server_transitions] = useState([])
    const [hide_thumbnails, setHide_thumbnails] = useState(false)
    const [container_width, set_container_width] = useState(0)
    const [container_height, set_container_height] = useState(0)
    const scroll_images_ref = useRef([])
    const player_ref = useRef()
    const trans_ref = useRef([])
    const canvas_frames = useRef([])
    const container_ref = useRef();
    const [refresh_scroll, setRefreshScroll] = useState(0)
    const is_scrolling = useRef(false)

    useEffect(() => {
        const handleResize = (entries) => {
            for (let entry of entries) {
                set_container_width(entry.contentRect.width);
                set_container_height(entry.contentRect.height);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (container_ref.current) {
            resizeObserver.observe(container_ref.current);
        }

        return () => {
            if (container_ref.current) {
                resizeObserver.unobserve(container_ref.current);
            }
        };
    }, []);
    useEffect(() => {
        axios.get(env.SERVER_REQUEST_PATH + 'musicTracks')

            // axios.post(env.SERVER_REQUEST_PATH + 'summarize_doc', req_sec_data)
            .then((response) => {
                let tracks = response.data
                const rows = [];
                for (let i = 0; i < tracks.length; i += 4) {
                    const rowNumbers = tracks.slice(i, i + 4);
                    const row_figs = []
                    rowNumbers.map((r) => {
                        let path = r
                        let parts = r.split('/')
                        let full_name = parts[parts.length - 1]
                        let name = full_name.slice(0, -4)
                        row_figs.push({
                            track_path: env.SERVER_REQUEST_PATH + path,
                            track_name: parts[parts.length - 1],
                            displayed_name: name
                        })

                    })
                    rows.push(row_figs);
                    // intialState.push(row_figs)
                }
                set_server_tracks(rows)

                set_selectedTrack(rows[0][0])
                set_selectedAudioName(rows[0][0].displayed_name)
            }).catch(err => {
                props.handleErrors(err)
                // set_stop_process(true)
                showBoundary(err)
            })

        axios.get(env.SERVER_REQUEST_PATH + 'transitions')

            // axios.post(env.SERVER_REQUEST_PATH + 'summarize_doc', req_sec_data)
            .then((response) => {
                let trans = response.data
                const rows = [];
                for (let i = 0; i < trans.length; i += 4) {
                    const rowNumbers = trans.slice(i, i + 4);
                    const row_figs = []
                    rowNumbers.map((r) => {
                        let path = r
                        let parts = r.split('/')
                        let full_name = parts[parts.length - 1]
                        let name = full_name.slice(0, -4)
                        row_figs.push({
                            gif_path: env.SERVER_REQUEST_PATH + path,
                            trans_name: name
                        })

                    })
                    rows.push(row_figs);
                    // intialState.push(row_figs)
                }
                set_server_transitions(rows)
            }).catch(err => {
                props.handleErrors(err)
                // set_stop_process(true)
                showBoundary(err)
            })


    }, [])

    useEffect(() => {
        let play_flags = []
        props.sections.map((sec, index) => play_flags[index] = false)
        is_play.current = play_flags
    }, [show_index])

    useEffect(() => {
        setSummarized_sections(props.sections)
    }, [props.sections])

    useEffect(() => {
        if (props.sections.length > 0 && scroll_images_ref.current.length <= 0) {
            let thumbnails = []
            props.sections.map((section) => {
                thumbnails.push({ url: '' })
            })
            scroll_images_ref.current = thumbnails
            setRefreshScroll(Math.random())
        }
    }, [props.sections])
    const handleTextBackgroundChange = (color) => {
        setTextBackground(color);
    };

    // Function to handle text border color change
    const handleTextBorderColorChange = (color) => {
        setTextBorderColor(color);
    };

    // Function to handle text border width change
    const handleTextBorderWidthChange = (value) => {
        setTextBorderWidth(parseInt(value));
    };

    const handleTextChange = (value) => {
        setText(value);
    };

    const handleTextColorChange = (color) => {
        setTextColor(color);
    };

    const handleTextFontChange = (font) => {
        setTextFont(font);
    };

    const handleFontSizeChange = (value) => {
        setFontSize(parseInt(value));
    };
    const handleBorderRadiusChange = (value) => {
        setBorderRadius(parseInt(value));
    };
    const handleOpacityChange = (value) => {
        setvideoOpacity(parseFloat(value));
    };
    const handleBKOpacityChange = (value) => {
        setBackgroundOpacity(value)
    }
    const showMusicPopup = () => {
        set_musicpopup_show(true)
    }
    const select_trans = (index) => {
        set_selectedTrans(index)
        set_transpopup_show(true)
    }
    const pauseAllFrames = () => {
        is_play.current.map((sec, index) => is_play.current[index] = false)
    }
    const toogleTrack = () => {
        let toggle = !track_is_play
        if (track_is_play)
            player_ref.current.audio.current.pause()

        else
            player_ref.current.audio.current.play()

        set_track_is_play(toggle)
    }
    const toggleplay = () => {
        // let toggle = !is_play[show_index]
        is_play.current[show_index] = !is_play.current[show_index]
        setRefreshScroll(Math.random())
        // set_is_play(toggle)
    }
    const replaceFrame = (frame_index, newFrame) => {
        canvas_frames.current[frame_index] = newFrame
        scroll_images_ref.current[frame_index] = { url: newFrame.thumbnail }
        setRefreshScroll(Math.random())
    }
    const updateFrameText = (newtext1, newtext2, frame_index) => {
        canvas_frames.current[frame_index].text = newtext1
        canvas_frames.current[frame_index].text2 = newtext2
    }
    const setFramesData = (frameData, frame_index) => {
        canvas_frames.current[frame_index] = frameData
        scroll_images_ref.current[frame_index] = { url: frameData.thumbnail }
        setRefreshScroll(Math.random())
    }
    const getUniqueFrame = (frames_id) => {
        if (canvas_frames.current.length > 0) {
            const canvas_frames_id = new Set(canvas_frames.current.map(f => f.id));

            const filteredArr = frames_id.filter(id => !canvas_frames_id.has(id));
            // let filter_index = Math.floor(Math.random() * ((filteredArr.length - 1) - 0 + 1)) + 0;
            // if (filteredArr[filter_index])
            // return filteredArr[0]
            // else
            if (filteredArr.length <= 0)
                return frames_id[0]

            return filteredArr[0]
        }
        return frames_id[0]
    }
    const previous_canvas = () => {

        is_scrolling.current = true

        if (!hide_thumbnails) {
            let prev_index = scroll_index.current
            prev_index--
            if (prev_index > 0)
                scroll_index.current = prev_index
            else
                scroll_index.current = 0
        }
        else {
            let prev_index = show_index
            prev_index--
            if (prev_index > 0)
                set_show_index(prev_index)
            else
                set_show_index(0)
        }
        setRefreshScroll(Math.random)

    }
    const next_canvas = () => {
        is_scrolling.current = true
        if (!hide_thumbnails) {
            let next_index = scroll_index.current
            next_index++
            if (next_index < summarized_sections.length)
                scroll_index.current = next_index
            else
                scroll_index.current = 0
        }
        else {
            let next_index = show_index
            next_index++
            if (next_index < summarized_sections.length)
                set_show_index(next_index)
            else
                set_show_index(0)
        }
        setRefreshScroll(Math.random)

    }
    const submitFrames = () => {
        props.submitFrames(canvas_frames.current, selectedTrack?.track_name, trans_ref.current)
    }
    return (
        <div>
            <div className='generate-controls d-flex align-items-md-start align-items-center justify-content-between d-flex flex-column flex-md-row gap-3 py-2 px-2 px-md-5 w-100'>
                <label className='gen-title-style'>Edit your video</label>
                <div className='d-flex align-items-end justify-content-end gap-3'>
                    <Button className='px-3' size="sm" variant="outline-primary"
                        onClick={() => {
                            props.regenerate(true)
                            setIsRegenerate(Math.random())
                        }} >Regenerate
                    </Button>

                    <Button
                        className='px-3' variant="primary" size="sm"
                        onClick={submitFrames}>Complete
                    </Button>
                </div>
            </div>
            <div className='w-100 d-flex justify-content-center'>
                <div className={`${hide_thumbnails ? 'canvas-container-expand' : 'canvas-container'} d-flex justify-content-center`}>
                    <div ref={container_ref} className={`d-flex w-100 flex-column align-items-center position-relative canvas-wrapper`}>
                        {summarized_sections.map((section, section_index) => {
                            return (
                                <div className='canvas-width d-flex justify-content-center align-items-center'>
                                    <div className={`${hide_thumbnails ? 'canvas-style-expand' : 'canvas-style'}`} hidden={show_index == section_index ? '' : 'hidden'}>
                                        <Canvas
                                            doc_id={props.doc_id}
                                            text={section.longSummary}
                                            text2={section_index == 0 ? section.paperAuthors : ''}
                                            keyword={section.shortSummary}
                                            textColor={textColor} textBackground={textBackground}
                                            textBackground2={textBackground2}
                                            textBorderWidth={textBorderWidth} textBorderColor={textBorderColor}
                                            textFont={textFont} fontSize={fontSize} borderRadius={borderRadius}
                                            videoOpacity={videoOpacity} backgroundOpacity={backgroundOpacity}
                                            setText={(value) => setText(value)}
                                            setFontSize={(fontsize) => { setFontSize(fontsize) }}
                                            canvas_index={section_index}
                                            interest={props.interest}
                                            subject={props.subject}
                                            videoIsTitle={section_index == 0 ? true : false}
                                            parentContainer_width={container_width}
                                            sendnewVideo={(newFrame) => {
                                                replaceFrame(section_index, newFrame)
                                            }}
                                            isRegenerate={isRegenerate}
                                            is_play={is_play.current[section_index]}
                                            pauseAllFrames={pauseAllFrames}
                                            setFramesData={(frameData, frame_index) => { setFramesData(frameData, frame_index) }}
                                            getUniqueFrame={getUniqueFrame}
                                            copyPrevFrame={() => { return canvas_frames.current[0] }}
                                            updateFrameText={updateFrameText}
                                            hide_thumbnails={hide_thumbnails}
                                            beginProcess={props.beginProcess}
                                            endProcess={props.endProcess}
                                            handleErrors={props.handleErrors}
                                        />
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>
                <div className={`w-auto mb-lg-4 ms-lg-2 mb-md-4 ms-md-3 mb-4 ms-4 d-flex justify-content-end align-items-end`}>
                    <div className={`p-0 m-0 row w-auto track-controls d-flex justify-content-start`}>
                        <div title='Change Track' role='button' className='p-0 m-lg-0 me-lg-1 m-md-0 me-md-2 mb-md-2 mb-1 col-lg-6 col-6 col-md-6 p-1 track-name me-2' onClick={() => showMusicPopup()}>
                            {/* {selectedAudioName} */}
                            <MdOutlineQueueMusic size={23} color='#5644de' />
                        </div>
                        <button disabled={selectedTrack ? '' : 'disabled'} className='p-0 m-0 col-lg-5 col-6 col-md-6 p-1 icon-btn' onClick={toogleTrack} >
                            {!track_is_play && <MdReplay title='Play track' size={20} color='#5644de' />}
                            {track_is_play && <MdOutlinePause title='Pause track' size={20} color='#5644de' />}
                        </button>
                    </div>
                </div>
            </div>
            <div className='position-relative w-100 p-2 thumb-scroll d-flex flex-column gap-1 justify-content-center align-items-center '>
                <div className='d-flex justify-content-center control w-100 controls'>

                    <div className='w-100 d-flex justify-content-center align-items-center play_controls gap-3'>
                        <FiChevronsLeft size={20} title='Previous' color='#5644de' role='button' onClick={() => {
                            previous_canvas()
                        }} />
                        {!is_play.current[show_index] && <FiPlay color='#5644de' title='Play Video' size={20} role='button' onClick={toggleplay} />}
                        {is_play.current[show_index] && <FiPauseCircle color='#5644de' title='Pause Video' size={20} role='button' onClick={toggleplay} />}
                        <FiChevronsRight title='Next' color='#5644de' size={20} role='button' onClick={() => {
                            next_canvas()
                        }} />
                    </div>
                </div>
                {!hide_thumbnails && <div className='video_bkg'>
                    <ImageScroller imgs={scroll_images_ref.current}
                        is_urlPath={true} selectedImage={scroll_index.current}
                        scroll={is_scrolling.current}
                        trans_clicked={select_trans}
                        imageClicked={(index, is_scroll) => {
                            set_show_index(index)
                            scroll_index.current = index
                            is_scrolling.current = is_scroll
                        }} />
                </div>}
                <div role='button' className={`hide-bk-icon`}>
                    {!hide_thumbnails && <IoIosArrowDown size={15} onClick={() => {
                        setHide_thumbnails(true)
                    }} />}
                    {hide_thumbnails && <IoIosArrowUp size={15} onClick={() => {
                        setHide_thumbnails(false)
                    }} />}
                </div>
            </div>

            {/* <div className='toolbox-width border-start toolbox-style border-1'>
                    <ToolBox
                        text={text}
                        textColor={textColor}
                        textFont={textFont}
                        fontSize={fontSize}
                        textBackground={textBackground}
                        textBorderColor={textBorderColor}
                        textBorderWidth={textBorderWidth}
                        borderRadius={borderRadius}
                        videoOpacity={videoOpacity}
                        backgroundOpacity={backgroundOpacity}
                        handleTextChange={handleTextChange}
                        handleTextColorChange={handleTextColorChange}
                        handleTextFontChange={handleTextFontChange}
                        handleFontSizeChange={handleFontSizeChange}
                        handleTextBackgroundChange={handleTextBackgroundChange}
                        handleTextBorderColorChange={handleTextBorderColorChange}
                        handleTextBorderWidthChange={handleTextBorderWidthChange}
                        handleOpacityChange={handleOpacityChange}
                        handleBorderRadiusChange={handleBorderRadiusChange}
                        handleBKOpacityChange={handleBKOpacityChange}
                    />
                </div> */}

            <SearchMusicTracks closeModal={(selected_track) => {
                set_musicpopup_show(false)
                if (selected_track?.displayed_name)
                    set_selectedAudioName(selected_track?.displayed_name)
                set_selectedTrack(selected_track)
            }} tracks={server_tracks} showModal={musicpopup_show} />
            <Transitions transitions={server_transitions}
                showModal={transpopup_show} closeModal={(selected_transtion) => {
                    trans_ref.current[selectedTrans] = selected_transtion
                    set_transpopup_show(false)
                }} />
            <div hidden>
                <AudioPlayer ref={player_ref} onLoadedData={() => {
                    player_ref.current.audio.current.pause();
                }}
                    src={selectedTrack?.track_path}
                />
            </div>
        </div>

    )
}

export default VideoBackgrounds
