import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import '../css/Notification.css'
import { FaUserCircle } from 'react-icons/fa'
import { BsFillBellFill } from 'react-icons/bs'
import axios from 'axios';
import { useStateValue } from "../../index";
import { PropagateLoader } from 'react-spinners';
const env = require('../../config.env')
const Notifications = () => {
    const [notifications, setNotifications] = useState([])
    const [unreadcount, setunreadcount] = useState(0)
    const [userDetails, dispatch] = useStateValue();
    const [notifications_loaded, set_notifications_loaded] = useState(false)

    useEffect(() => {
        const id = setInterval(() =>
            getUreadNotifications()
            , 60000);

        return () => clearInterval(id);
    }, []);
    useEffect(() => {
        getUreadNotifications()
    }, [])
    const getUreadNotifications = () => {
        if (userDetails.user) {
            const token = localStorage.getItem("user-token")
            if (token)
                axios.get(`${env.SERVER_REQUEST_PATH}unreadcount`)
                    .then((response) => {
                        setunreadcount(response.data)
                    }).catch(err => {
                        // props.catchErrors(err)
                        // console.error(`Error: ${err}`)
                    })
        }
    }
    const getNotifications = () => {
        set_notifications_loaded(false)
        axios.get(`${env.SERVER_REQUEST_PATH}mynotifications`)
            .then((response) => {
                setNotifications(response.data.data)
                set_notifications_loaded(true)
            }).catch(err => {
                // props.catchErrors(err)
                // console.error(`Error: ${err}`)
            })
    }
    const notificationIsRead = (not_id) => {
        axios.post(`${env.SERVER_REQUEST_PATH}markasread`, { notification_id: not_id })
            .then((response) => {
                getUreadNotifications()
            }).catch(err => {
                // props.catchErrors(err)
                // console.error(`Error: ${err}`)
            })
    }
    return (
        <div>
            <Dropdown className='notifications mt-1 me-2 w-sm-100' onClick={getNotifications}>
                <Dropdown.Toggle variant="light" className='py-2'>
                    <BsFillBellFill color={"#5644de"} />
                    {unreadcount > 0 && <span
                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {unreadcount}
                        <span class="visually-hidden">unread messages</span>
                    </span>}

                </Dropdown.Toggle>
                <Dropdown.Menu className='p-2' align="end">
                    {notifications <= 0 && !notifications_loaded &&
                        <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                            <PropagateLoader color="#B9AFF7" />
                        </div>}
                    {
                        notifications.map((not) => {
                            return (<Dropdown.Item onClick={() => notificationIsRead(not.id)}
                                href={not.link}>
                                {not.is_read > 0 && <span className='unread-dot me-2'>•</span>}
                                {not.profile_img && <div><img src={env.SERVER_REQUEST_PATH + not.profile_img} className="not_img" /></div>}
                                {!not.profile_img &&
                                    // <img src='/assets/user.png' className='not_img' />
                                    <span>
                                        <FaUserCircle size={30} color='6e757c' />
                                    </span>
                                }
                                <span className="text-wrap d-inline-block ms-2">{not.content}</span>
                            </Dropdown.Item>)
                        })}

                </Dropdown.Menu>
            </Dropdown>

        </div>
    )
}

export default Notifications
