import axios from 'axios';

export function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    return ret;
}
export function getTimeSinceCreation(date) {
    const moment = require('moment')
    let time =
        moment.utc(date).local().startOf('seconds').fromNow()
    return time
}
export function kFormatter(num) {
    if (!num)
        return 0;
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

export function is_allowedPage() {
    const env = require('../config.env')
    let pagename = getCurrentPage()
    let allowed = env.allowedPages.find((item) => item.toLowerCase() == pagename.toLowerCase())
    if (pagename == '')
        return true
    return allowed
}
export function is_publicPage() {
    const env = require('../config.env')
    let pagename = getCurrentPage()
    let allowed = env.publicPages.find((item) => item.toLowerCase() == pagename.toLowerCase())
    return allowed
}
export function getCurrentPage() {
    let pagename = window.location.href.split('/')[3];
    let pagename_con = pagename.split('?')[0];

    if (pagename_con)
        pagename = pagename_con
    let pagename_hash = pagename.split('#')[0];
    if (pagename_hash)
        pagename = pagename_hash
    return pagename
}
export function checkPermission(permissions) {
    let found = true
    let pagename = getCurrentPage()
    let allowed = is_allowedPage()
    if (!allowed) {
        let page = null
        if (permissions) {
            page = permissions.find((item) => item.page_name.toLowerCase() == pagename.toLowerCase())
        }
        found = page ? true : false

    }
    return found

}
export function checkPagePermission(permissions, pagename) {
    let found = true
    let page = null
    if (permissions) {
        page = permissions.find((item) => item.page_name.toLowerCase() == pagename.toLowerCase())
    }
    found = page ? true : false
    return found

}
export function formatNoWordsToMinSec(words) {
    const env = require('../config.env')
    const est_min = (words / env.NO_WORDS_PER_MIN)
    const seconds = est_min * 60
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}
export function filterNonUTF8Characters(text) {
    // Create a regular expression to match valid UTF-8 characters
    const validUTF8Regex = /[^\x00-\x7F]+/g;

    // Use the regex to filter out non-UTF-8 characters
    const filteredText = text.replace(validUTF8Regex, '');

    return filteredText;
}
export function getVideos_pex(keyword, page, per_page) {
    // const axios = require('axios');
    // const apiUrl = 'https://api.shutterstock.com/v2/videos/search';
    const apiUrl = 'https://api.pexels.com/videos/search';
    let app_key = 'dyZumv78YxjXbSpt6wXLNKnVWKargO9zmdDucW0nWNNJWcVGiNPydlPL'
    // const accessToken = 'v2/emNuYWVNRDJyZ3ppeFd6Mmo4MWUzM0RXNFBTWEYzT0wvNDMwNTM3Nzg1L2N1c3RvbWVyLzQvVDhiWFdXSXNfRENfNm01eXlvNjVubXhveGFveTU1TnF2dTgtWHpWbThrZVBDOFNabmJqaVVLLWZJOHZiSXFVOC05cXdsdlpTYXh4WktQQmkyakdxb0cyMExTS1RMM3dRQVZ5OFNzRkhxWlB6WlRMUjhPQ0FMbm9xRHVMNmdKYVR5MndpYndkTnJVcFZ4V01LV1N2cGdKbEFqRGtWVV9yVUFLUlJpU2JOT0l1OC0yUjRzV3Q1Sl9ZWFdJcE5ZRjYzVnRHOW91d0ZpNi1lb2V1aERXZTJxUS8zbU1Udi14c3RoX2pXTGFDZlhwOGN3';

    const searchParams = {
        query: encodeURIComponent(keyword), // example search query
        // image_type: 'vector',
        // resolution: '4k',
        // resolution: 'high_definition',
        // aspect_ratio: '16_9',
        // ai_search: true,
        // ai_objective: 'academic',
        // ai_industry: 'engineering',
        // image_type: 'gif',
        orientation: 'landscape',
        size: 'medium',
        page: page, // page number
        per_page: per_page, // number of results per page
    };
    const axiosConfig = {
        headers: {
            // Authorization: `Bearer ${accessToken}`
            Authorization: app_key
        },
        params: searchParams,
    };

    return axios.get(apiUrl, axiosConfig)
        .then(response => {
            let videosFiles = []
            response.data.videos.map((v) => {
                let v_file = v.video_files.
                    filter(y => y.width >= 1920 && (y.width / y.height).toFixed(3) === '1.778')[0]
                if (v_file)
                    videosFiles.push({
                        link: v_file.link,
                        picture: v.video_pictures[0].picture,
                        id: "px" + v.id, height: v_file.height, width: v_file.width
                    })
            })
            return videosFiles;
        })
}

export function getVideos_shuttersk(keyword, subject, page, per_page) {
    // const sstk = require("shutterstock-api");

    // const applicationConsumerId = "zcnaeMD2rgzixWz2j81e33DW4PSXF3OL";
    // const applicationConsumerSecret = "m9BeGQj0Yec9TNCA";
    // sstk.setBasicAuth(applicationConsumerId, applicationConsumerSecret);

    // const videosApi = new sstk.VideosApi();
    // const axios = require('axios');
    const apiUrl = 'https://api.shutterstock.com/v2/videos/search';
    // let app_key = 'dyZumv78YxjXbSpt6wXLNKnVWKargO9zmdDucW0nWNNJWcVGiNPydlPL'
    const accessToken = 'v2/emNuYWVNRDJyZ3ppeFd6Mmo4MWUzM0RXNFBTWEYzT0wvNDMwNTM3Nzg1L2N1c3RvbWVyLzQveVhQejAxY2RqUnRfWEpSVW4tSG5FdTFaTU5mams0NzBsS3NoLUVFdGRaWmVJampMTmE1VG01M2pWQ0hJMVpKamk0UThxeE81Y0NtM1RDamVoS3dXZkllTkFhLUxyZHBiVGdJV3FxaWpZUkV3bGx1S3h1NHZOSjAwVUxzQ3ZtNUdHeEIzNGxLbTdra01FVkNGYi1leTJ2bFFYVkpoMTFNdDBWT3psYjlWTGRUYWJweUFNXzVFQ1I5TFhKRFBtMkpjZWVxS01zTjc0eEQ1bmczUjQyMmhrZy9YT1ZwWVh2VXZ1NnZVUFJ1bDFtUzVB';

    const searchParams = {
        query: encodeURIComponent(keyword), // example search query
        // image_type: 'vector',
        // resolution: '4k',
        resolution: 'high_definition',
        aspect_ratio: '16_9',
        ai_search: true,
        ai_objective: 'academic',
        ai_industry: subject,
        // category: subject,
        // image_type: 'gif',
        // orientation: 'landscape',
        // size: 'medium',
        page: page, // page number
        per_page: per_page, // number of results per page
    };
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${accessToken}`
            // Authorization: app_key
        },
        params: searchParams,
    };

    return axios.get(apiUrl, axiosConfig)
        .then(response => {
            let videosFiles = []
            response.data.data.map((v) => {
                if (v.assets)
                    videosFiles.push({
                        link: v.assets.preview_mp4.url,
                        picture: v.assets.thumb_jpg.url,
                        id: "sh" + v.id, height: 336, width: 596
                    })
            })
            return videosFiles;
        }).catch((error) => { return {} })
}