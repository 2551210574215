import React, { useEffect, useRef, useState } from 'react'
import VideoViewer from '../../SharedComponents/js/VideoViewer'
import { Button, Form, Modal } from 'react-bootstrap'
import FormInput from '../../SharedComponents/js/FormInput'
import axios from 'axios';
import { FaGift } from 'react-icons/fa';
import { AiOutlineGift } from 'react-icons/ai';
import UploadFile from '../../SharedComponents/js/UploadFile';

const env = require('../../config.env')

const UploadFrame = (props) => {
    const isShow = useRef(false)
    const [refresh_modal, set_refresh_modal] = useState()
    const handleClose = () => {
        isShow.current = false
        set_refresh_modal(Math.random())
        props.closeModal()
    }
    useEffect(() => {
        isShow.current = props.showModal
        set_refresh_modal(Math.random())
    }, [props.showModal])
    const upload_doc = (file) => {
        if (file) {
            const formData = new FormData();
            const fileExtension = file.name.split('.').pop();
            let newFile_name =
                fileExtension == "mp4" ? 'video' + props.frame_index + '.' + fileExtension :
                    'image' + props.frame_index + '.' + fileExtension
            const renamedFile = new File([file], newFile_name, { type: file.type });
            let height; let width;
            if (fileExtension == "mp4") {
                const videoElement = document.createElement('video');
                videoElement.src = URL.createObjectURL(renamedFile);

                // Load metadata to get dimensions
                videoElement.onloadedmetadata = () => {

                    width = videoElement.videoWidth
                    height = videoElement.videoHeight
                    // Clean up the object URL after metadata is loaded
                    URL.revokeObjectURL(videoElement.src);
                };
            }
            else {
                const img = new Image();
                img.src = URL.createObjectURL(file);

                // Wait for the image to load
                img.onload = () => {
                    width = img.width
                    height = img.height
                    // Clean up the object URL after the image is loaded
                    URL.revokeObjectURL(img.src);
                }
            }
            formData.append("file", renamedFile)
            let headers = { 'content-type': 'multipart/form-data', "doc_id": props.doc_id }
            props.beginProcess()
            handleClose()
            try {
                axios.post(env.SERVER_REQUEST_PATH + 'upload_frame', formData, {
                    headers: headers
                }).then((res) => {
                    props.endProcess()
                    if (fileExtension == "mp4")
                        props.submitFrame(`data:video/mp4;base64,${res.data}`, height, width)
                    else
                        props.submitImage(`data:image/${fileExtension};base64,${res.data}`, height, width, newFile_name)

                });
            } catch (error) {
                props.handleErrors(error)
                // console.error('Failed to submit files.');
            }
        }
    }

    return (
        <Modal className='upload-modal' size='lg' show={isShow.current} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload your Frame</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <UploadFile uploadFile={upload_doc}
                    dragdrop={true}
                    label={'Click here to upload your file or drag and drop.'}
                    formatText={`Suported formats: Videos and Images`}
                    fileType={'video/mp4,image/*'}
                    disabled={false}
                    // fileType={['pdf']}
                    supportedTypes={"mp4 and Images"}
                    labelAfterUpload="Uploaded Successfully!"
                />
            </Modal.Body>

        </Modal>
    )

}

export default UploadFrame
